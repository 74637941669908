@import url(https://fonts.googleapis.com/css?family=Libre+Franklin:300,500,700);

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'AkMedCon';
  font-weight: normal;
  src: url(../fonts/AkzidenzGroteskBQ-MdCnd.woff2) format("woff2"),
  url(../fonts/AkzidenzGroteskBQ-MdCnd.woff) format("woff") ; 
}

@font-face {
  font-family: 'AkMedCon';
  font-weight: normal;
  font-style: italic;
  src: url(../fonts/AkzidenzGroteskBQ-MdCndIt.woff2) format("woff2"),
  url(../fonts/AkzidenzGroteskBQ-MdCndIt.woff) format("woff") ; 
}
 
@font-face {
  font-family: 'AkMed';
  font-weight: normal;
  font-style: normal;
  src: url(../fonts/AkzidenzGroteskBQ-Medium.woff2) format("woff2"),
  url(../fonts/AkzidenzGroteskBQ-Medium.woff) format("woff") ; 
}


@font-face {
  font-family: 'AkReg';
  font-weight: normal;
  src: url(../fonts/AkzidenzGroteskBQ-Reg.woff2) format("woff2"),
  url(../fonts/AkzidenzGroteskBQ-Reg.woff) format("woff") ; 
}

@font-face {
  font-family: 'AkReg';
  font-weight: normal;
  font-style: italic;
  src: url(../fonts/AkzidenzGroteskBQ-Italic.woff2) format("woff2"),
  url(../fonts/AkzidenzGroteskBQ-Italic.woff) format("woff") ; 
}
  
/* @font-face {
  font-family: 'AkMed';
  font-weight: normal;
  src: url(../fonts/AkzidenzGrotesk-Medium.otf); } */

html, body{
    font-family: "AkReg", sans-serif;
}

h1, h2, h3, h4, h5 {
  @apply font-AkMedCon;
}

.homepage-title h1 {
  @apply text-[13vw] md:text-[10vw] uppercase leading-[13vw] md:leading-[10vw];
  /* line-height: 10vw; */
}

.homepage-title h5 {
  @apply text-[5vw] md:text-[3vw];
}

p {
  @apply mb-4;
}


a:hover {
  /* @apply text-ts-blue; */
}

.text-shadow {
    text-shadow: 2px 2px 2px hsla(30, 4%, 18%, 0.5), 0px 0px 10px hsla(30, 4%, 18%, 0.25);
}

.wordspacing-xl {
  word-spacing: 200rem;
}

/* prose force */

.prose blockquote {
    @apply border-0 pl-0;
}

.prose ol, .prose ul {
  @apply list-outside -ml-6;
}

.prose ol > li, .prose-xl ol > li, .prose ul > li, .prose-xl ul > li  {
  @apply  !pl-6;
}

div.prose > ol > li::before {
  @apply text-ts-blue;
}

.prose-xl ul > li::before {
  top: calc(0.9em - 0.4em);
}

div.prose > ul > li::before {
  @apply bg-ts-blue;
}

.prose figure figcaption, 
.prose-lg figure figcaption, .wp-caption-text  {
    @apply text-sm !important;
}

/* ---------------------------- Repeated elements ---------------------------- */

.page-head {
  @apply text-center font-AkMedCon text-4xl uppercase pt-5 mx-auto px-20 md:px-0;
}

.exhib-head {
  @apply text-center font-AkMedCon text-4xl pt-5 mx-auto px-20 md:px-0;
}

.section-head {
  @apply text-center font-AkMedCon text-4xl uppercase pt-20 pb-4 mx-auto;
}

.clamp-adjust {
  height: calc(100% - 18px); /* adjusts for mb-4 */
}

.card-date {
  @apply  pt-2 text-ts-blue text-2xl 1xl:text-3xl font-AkMedCon;
}