
.lazyload,
.lazyloading {
    opacity: 0
;
}

.loading,
.lazyload,
.lazyloaded,
.image {
    transition: 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.lazyloaded {
    opacity: 1;
}



/* ---------------------------- Repeated elements ---------------------------- */

.linktile {
  @apply flex-1 min-w-full max-w-full md:min-w-[30%] md:max-w-[50%];
  /* flex: 1;
  min-width: 30%;
  max-width: 50%; */
}

.facilitiestile {
  flex: 1;
  min-width: 30%;
  max-width: 33%;
}

@media (max-width: 640px) {
  .facilitiestile {
    flex: 1;
    min-width: 100%;
    max-width: 100%;
  }
}


.general-buttons {
  @apply w-max p-3 m-5 bg-ts-blue text-white items-center cursor-pointer hover:bg-white hover:text-ts-blue font-AkMedCon uppercase;
}

.framed-button {
  @apply font-AkReg text-xs border border-ts-blue text-ts-blue px-2 pt-1 pb-0.75 uppercase tracking-widest subpixel-antialiased;
}

.framed-button-lg {
  @apply cursor-pointer font-AkReg text-base md:text-xl border-2 border-ts-blue bg-ts-blue text-white px-3 pt-2 pb-2 uppercase tracking-widest hover:bg-ts-lightgrey hover:text-ts-blue transition-all duration-300 text-shadow-none;
}

.framed-button-inverted-lg {
  @apply cursor-pointer font-AkReg text-xl border-2 border-white bg-white text-ts-blue px-3 pt-2 pb-2 uppercase tracking-widest hover:bg-ts-blue hover:text-white transition-all duration-300 ;
}

#gallery-caption {
  @apply text-sm text-right max-w-[40vw] pb-1 1xl:pb-0 float-right md:line-clamp-1 1xl:line-clamp-none hidden md:block;
}

/* purgecss start ignore */

.filter-active {
  @apply bg-ts-lightgrey text-ts-blue;
}

/* purgecss end ignore */

.grid-item {
  transform:translate3d(0,0,0);
  -webkit-transform:translate3d(0,0,0);
  -moz-transform:translate3d(0,0,0);
}

.card-outer {
  @apply mb-8 px-4;
}

.card-inner {
  @apply p-4 transform transition-all duration-300 hover:scale-[1.01] shadow-ts hover:shadow-lg;
}

.exhibtile {
  flex: 1 1 0%;
  min-width: 43%;
  max-width: 65%;
  @apply w-max bg-white p-4 transform transition-all duration-300 hover:scale-[1.01] shadow-ts hover:shadow-lg;
  /* min-height: 70vh; */
}

@media (max-width: 640px) {
  .exhibtile {
  min-width: 100%;
  max-width: 100%;
  }
}

.coursetile {
  flex: 1 1 0%;
  --width100: calc(100vw - 14rem);
  min-width: calc(var(--width100) / 3);
  max-width: calc(var(--width100) / 3);
  
  /* min-width: 31%;
  max-width: 33%; */
  @apply w-max bg-white p-4 transform transition-all duration-300 hover:scale-[1.01] shadow-ts hover:shadow-lg;
  /* min-height: 70vh; */
}

@media (max-width: 640px) {
  .coursetile {
    flex: 1 1 0%;
    min-width: 100%;
    max-width: 100%;
  }
}

.artistTile {
  flex: 1 1 0%;
  --width100: calc(100vw - 16rem);
  min-width: calc(var(--width100) / 4);
  max-width: calc(var(--width100) / 4);
  
  /* min-width: 31%;
  max-width: 33%; */
  @apply flex flex-col w-max bg-white p-4 transform transition-all duration-300 hover:scale-[1.01] shadow-ts hover:shadow-lg;
  /* min-height: 70vh; */
}

@media (max-width: 640px) {
  .artistTile {
    min-width: 100%;
    max-width: 100%;
  }
}

.swiper-slide {
  -webkit-transform: translate3d(0, 0, 0);
}

.page-title {
  @apply flex h-14 md:h-24 items-center w-1/2 m-auto md:w-full mt-2 md:mt-0;
}

.card-content-margins {
  @apply px-4 xl:px-16
}

.submenu-open {
  max-height: 5000px !important;
  @apply transition-all duration-1000;
}

/* ---------------------------- Swiper w/Alla edit ---------------------------- */

#swiper-single {
  overflow: visible;
}

.swiper-slide {
  width: auto;
}

.swiper-pagination-bullet {
  margin-left: 10px;
}

.swiper-pagination-bullet {
  @apply bg-white bg-opacity-60 shadow-lg;
}

.swiper-pagination-bullet-active {
  @apply bg-white bg-opacity-90;
}

.left-arrow {
  cursor: url("../images/arrow_left.svg"), w-resize;
}

.left-arrow-white {
  cursor: url("../images/arrow_left_white.svg"), w-resize;
  @apply filter drop-shadow-lg;
}

.right-arrow {
  cursor: url("../images/arrow_right.svg"), e-resize;
}

.right-arrow-white {
  cursor: url("../images/arrow_right_white.svg"), e-resize;
}

.swiper-video {
  width: 80vw;
  max-height: 100%;
}

.timeline-dots {
  @apply h-4 w-4 md:h-8 md:h-8;
  transform: rotate(-90deg);
}

.swiper-slide-active {
  @apply opacity-100 transition-opacity duration-500;
}

#info-button {
  display: none;
}

/* purgecss start ignore */

.swiper-fade.swiper-free-mode .swiper-slide{transition-timing-function:ease-out}.swiper-fade .swiper-slide{pointer-events:none;transition-property:opacity}.swiper-fade .swiper-slide .swiper-slide{pointer-events:none}.swiper-fade .swiper-slide-active,.swiper-fade .swiper-slide-active .swiper-slide-active{pointer-events:auto}

/* purgecss end ignore */



