

#mc_embed_signup {
}

.mc-field-group {
}

.mc-field-group input[type='text'],
.mc-field-group textarea,
.mc-field-group input[type='email'] {
    @apply w-full placeholder-white placeholder-opacity-70 text-white text-5xl font-AkMedCon bg-transparent  mb-5 outline-none border-0 border-b border-white pl-0;
    box-shadow: none !important;
}

.mc-field-group textarea {
    @apply mb-0;
}

.mc-field-group input[type='text']:focus,
.mc-field-group textarea:focus,
.mc-field-group input[type='email']:focus {
    @apply border-white
}

.radiolist {
    @apply list-none;
}

.radiolist label {
    @apply text-white font-AkReg text-xl pl-10;
}

.mc-field-group input[type='radio'] {
    @apply mb-1.5;
}

.radiolist label {
    @apply pl-5;
}



/* ---------------------------- General mc forms ---------------------------- */

form div {
  @apply pb-3
}
